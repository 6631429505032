import React, { Component } from 'react'
import PackeryModule from 'react-packery-component'
import Dropdown from 'components/dropdown'
import Layout from 'components/layout'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Title from 'components/title'
import { projectsStandardSort, trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

const Packery = PackeryModule(React)
const packeryOptions = {
  itemSelector: '.item-grid',
  percentPosition: true,
  gutter: '.gutter-sizer',
  transitionDuration: '0', //'0.4s'
}

const Project = ({ project, activeFilterValue }) => {
  return (
    <div
      className={
        'item-grid item-black-white filterItem ' +
        (activeFilterValue === project.category.slug ||
        activeFilterValue === 'all'
          ? 'show'
          : '')
      }
    >
      <LinkWrap to={`/bespoke/projects/${project.slug}`}>
        <ImageWrap image={project.image} aspectRatio={1.32} />
      </LinkWrap>
      <div className="caption">
        <div className="small-caps date">{project.location.title}</div>
        <LinkWrap to={`/bespoke/projects/${project.slug}`} className="dark">
          <div className="title">{project.title}</div>
        </LinkWrap>
      </div>
    </div>
  )
}

const ProjectCat = ({ slug, title, setFilterValue, activeFilterValue }) => {
  return (
    <li
      data-slug={slug}
      onClick={setFilterValue}
      className={activeFilterValue === slug ? 'selected' : ''}
    >
      <a className="ui">{title}</a>
    </li>
  )
}

class ProjectsPage extends Component {
  constructor() {
    super()
    this.state = {
      activeFilterValue: 'all',
    }
    this.setFilterValue = this.setFilterValue.bind(this)
  }

  setFilterValue(e) {
    const dataSlug = e.currentTarget.getAttribute('data-slug')
    this.setState({ activeFilterValue: dataSlug })
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const page = this.props.data.thisPage
    const allProjects = projectsStandardSort(this.props.data.projects.edges)
    const projectCats = this.props.data.projectCats.edges
    const defaultFilter = [{ node: { title: tr('ALL_PROJECTS'), slug: 'all' } }]
    const filters = defaultFilter.concat(projectCats)

    return (
      <Layout>
        <div id="content">
          <Title title={page.title} />
          <header id="projects-categories" className="categories-header">
            <div className="wrap categories-header-inner">
              <ul className="cats-desktop">
                {filters.map((projectCat, i) => (
                  <ProjectCat
                    key={i}
                    title={projectCat.node.title}
                    slug={projectCat.node.slug}
                    setFilterValue={this.setFilterValue}
                    activeFilterValue={this.state.activeFilterValue}
                  />
                ))}
              </ul>
            </div>
            <Dropdown
              filters={filters}
              setFilterValue={this.setFilterValue}
              activeFilterValue={this.state.activeFilterValue}
              tr={tr}
            />
          </header>
          <section className="page-content wrap">
            <div className="wrapper-grid wrapper-grid-projects">
              <Packery
                className={'grid-packery grid-packery-projects'}
                options={packeryOptions}
              >
                <div className="gutter-sizer" />
                {allProjects.map((project, i) => (
                  <Project
                    key={i}
                    project={project.node}
                    activeFilterValue={this.state.activeFilterValue}
                  />
                ))}
              </Packery>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default ProjectsPage

export const projectsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    projects: allContentfulProjects(
      sort: { fields: [datePosted], order: DESC }
    ) {
      edges {
        node {
          isFeatured
          datePosted
          slug
          title
          location {
            title
          }
          category {
            slug
          }
          image {
            ...GatsbyImage
          }
        }
      }
    }
    projectCats: allContentfulProjectCategories(
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "projects" }) {
      title
      slug
    }
  }
`
